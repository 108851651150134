@import '../scss/variables.scss';

.errorText {
  color: $errorColor;
  font-size: 13px;
}
.inputContainer {
  .labelName {
    width: 150px;
    font-size: 15px;
  }
}
