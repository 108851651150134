.icon {
  color: rgba(0, 0, 0, 0.25) !important;
}

.loginBtn {
  margin-top: 20px;
  align-items: start;
}

.loginContainer {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}
