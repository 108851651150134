.colHeading {
  font-weight: 500;
  white-space: noWrap;
  margin-right: 8px;
}
.colContent {
  font-weight: 500;
  color: purple;
  text-transform: capitalize;
}
