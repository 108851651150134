@import './scss/variables.scss';

body {
  margin: 0px !important;
  height: 100%;
}

.fullScreen {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subMenu {
  .ant-menu-item,
  .ant-menu-vertical {
    margin-bottom: 0px !important;
  }
  .ant-menu-item a {
    color: $colorWhite !important;
  }
  .ant-menu-item-selected a {
    color: $navBarBgColor !important;
  }

  .ant-menu-item-selected {
    border-bottom: 3px solid $navBarBgColor !important;
    color: $navBarBgColor !important;
  }
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.ant-menu-horizontal {
  line-height: 40px !important;
  border-bottom: none !important;
}

.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: black !important;
  color: white !important;
  border-bottom: none !important;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: none !important;
}

.ant-menu-item:hover::after {
  border-bottom: none !important;
}

.ant-menu-item-selected {
  background-color: $colorBlack !important;
  color: $colorWhite !important;
}
.ant-menu-item-selected::after {
  border-bottom: none !important;
}

.ant-menu-horizontal > .ant-menu-item-selected a {
  color: $colorWhite !important;
}

.requiredFieldBefore:before {
  content: '*';
  color: $errorColor;
  margin-right: 3px;
}

.requiredFieldAfter:after {
  content: '*';
  color: $errorColor;
  margin-left: 3px;
  font-size: large;
}

.ant-btn {
  border-radius: 5px !important;
}

.ant-input:focus,
.ant-input-focused {
  box-shadow: none !important;
}

.addDeductionBtn {
  &:hover {
    color: $colorBlack !important;
  }

  &:focus {
    box-shadow: none !important;
    color: $colorBlack !important;
  }
}

.ant-tabs-tab {
  font-size: 15px !important;
}

.ant-select-selector {
  border-radius: 5px !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-selector:hover,
.ant-select-open .ant-select-selector {
  box-shadow: none !important;
  border: 1px solid transparent !important;
}

.ant-picker {
  border: 1px solid $colorBlack !important;
  border-radius: 5px !important;
}

.ant-picker-focused {
  box-shadow: none !important;
}

.ant-input-number:focus,
.ant-input-number:active,
.ant-input-number-focused {
  border: 1px solid black !important;
  box-shadow: none !important;
  border-right-width: 0px !important;
}

.ant-input-number:hover {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.ant-modal-footer {
  border-top: none !important;
}

.ant-input-number-handler-wrap {
  border-bottom-right-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.ant-descriptions-row > td {
  padding-bottom: 9px !important;
}

/********* common style ********/

/* styling for high light row */
.highLightColor {
  background: $highLightColor !important;
}

/* styling for loading spinner */
.loadingSpinner {
  display: flex !important;
  justify-content: center !important;
  margin-top: 15px !important;
}

/* styling for error text */
.errorText {
  color: $errorColor;
}

/* styling for create and navigate global button */
.createAndNavigateButton {
  margin-top: 7px;
  padding-top: 3px;
  padding-bottom: 5px;
}

/* styling for antd table */
.tableStyle {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

/* table column name style*/
.tableColumnNameStyle {
  font-weight: normal;
  font-size: 12;
}
/* styling for search icon */
.searchIcon {
  color: $tintColor !important;
}
.descriptionTwoContainer {
  margin-left: 40% !important;
}
.descriptionOneContainer {
  margin-left: 10% !important;
  width: 40% !important;
}

/* inward shipment print page second description style */
.inwardShipmentPrintSecondDesc {
  margin-left: 40% !important;
}

/* inward shipment print page first description style */
.inwardShipmentPrintFirstDesc {
  margin-left: 10% !important;
  width: 40% !important;
}

.printDescriptions .ant-descriptions-item-label {
  font-size: 13px !important;
  font-weight: 500 !important;
}

.printDescriptions .ant-descriptions-item-content {
  font-size: 13px !important;
}

.millNameStyle {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif !important;
  font-size: x-large !important;
}

/********* Print CSS New Started ********/

@media print {
  @page {
    size: A4 Portrait;
    margin-top: 2mm !important;
    margin-left: 20mm !important;
    margin-right: 20mm !important;
    margin-bottom: 2mm !important;
  }

  /* main div for printing */
  .printRefDiv {
    width: 950px !important;
    display: block !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  }

  .printBillInUIHeader {
    display: none !important;
  }

  .printOfficeCopyHeader {
    display: block !important;
  }

  /* duplicate bill for printing */
  .printDuplicateBill {
    display: block !important;
    margin-top: 130px !important;
    break-inside: avoid !important;

    .printOfficeCopyHeader {
      display: none !important;
    }

    .printSellerCopyHeader {
      display: block !important;
    }
  }

  /* duplicate bill for printing during material unloading*/
  .printDuplicateMaterialUnloadingBill {
    display: block !important;
    margin-top: 350px !important;
    break-inside: avoid !important;

    .printOfficeCopyHeader {
      display: none !important;
    }

    .printSellerCopyHeader {
      display: block !important;
    }
  }

  .printDescriptions .ant-descriptions-item-label,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .boldContentInPrint {
    font-weight: bold !important;
  }
}

/********* Print CSS New Ended ********/

.descriptionTwoContainer {
  margin-left: 40% !important;
}

.descriptionOneContainer {
  margin-left: 10% !important;
  width: 40% !important;
}

.inwardShipmentPrint {
  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding-bottom: 1px !important;
  }
}

.purchaseBillPrintTable {
  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small tfoot > tr > th,
  .ant-table.ant-table-small tfoot > tr > td {
    padding: 1px !important;
  }
  .ant-table.ant-table-small .ant-table-thead > tr > th {
    background-color: #fff !important;
  }
}

/* styling for go back button */
.goBackButton {
  font-size: 18px;
  color: black !important;
  margin-right: 20px;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 7px 8px 7px 7px;
}

.goBackButton *:hover {
  cursor: pointer;
  color: $tintColor !important;
}

/* styling for tabs */
.tabLayout {
  margin-left: 60px;
  margin-right: 50px;
  margin-top: 35px;
}

/* styling for modal*/
.modalLayout {
  margin-left: 20px;
  margin-right: 20px;
}

/* button container styling */
.buttonContainer {
  display: flex;
  justify-content: center;
  column-gap: 10px;
}

/* delete button styling */
.deleteButton {
  background-color: $deleteUserIconColor !important;
  color: $colorWhite !important;
  border-radius: 6px !important;
}

/* table column name Style */
.tableColumnNameStyle {
  font-weight: normal;
  font-size: 12;
}

/* styling for button which is used to add entity in select options quickly */
.selectDropDownButton {
  padding: 4px 8px !important;
  cursor: pointer !important;
  border-color: transparent !important;
  color: $tintColor !important;
}

/* pagination box border styling */
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  border-color: $placeholderTextColor !important;
}

.ant-pagination.mini .ant-pagination-item:hover {
  border-color: $tintColor !important;
}

.ant-table-pagination {
  column-gap: 8px !important;
}

.ant-pagination.mini .ant-pagination-options:hover {
  border-color: $tintColor !important;
}

.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  border: 1px solid $placeholderTextColor !important;
}

.ant-pagination.mini .ant-pagination-next:hover {
  border: 1px solid $tintColor !important;
}
.ant-pagination.mini .ant-pagination-prev:hover {
  border: 1px solid $tintColor !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $tintColor !important;
}

/* inward shipment report table style */
.reportTable .ant-table.ant-table-small {
  font-size: 12px !important;
}

.reportTableContainer {
  th {
    color: #000595d9 !important;
    font-weight: 600 !important;
  }
}

/* inward shipment report print page styling */
.printPageDivider .ant-divider-horizontal {
  width: 50% !important;
  min-width: 80% !important;
  margin: 0 auto !important;
}

.printModalLayoutStyle .ant-modal-header {
  border-bottom: none !important;
}

.printHtmlTable {
  width: 100% !important;
}
.printHtmlTable,
.printHtmlTable tr td,
.printHtmlTable tr th {
  border: 1px solid #ccc !important;
  padding: 2px 10px !important;

  .td-num {
    text-align: right;
  }
}

.materialUnloadingDescriptionHeader .ant-descriptions-header {
  margin-bottom: 5px;
}

.inwardShipmentPrint {
  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding-bottom: 1px !important;
  }
}

.editCompletedGRNModal {
  .ant-modal-content {
    margin-bottom: 30px !important;
    margin-top: 30px !important;
  }
  .ant-modal-body {
    padding-bottom: 15px !important;
    padding-top: 10px !important;
  }
  .ant-modal-header {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
}

/* edit internal shipment modal style */
.editInternalShipmentModal .ant-modal-body {
  padding-bottom: 5px !important;
}
/* radio buttons group option styling, for outward->product->shipment order screen */
.shipmentOrderRadioBtn {
  .ant-radio-wrapper {
    padding-bottom: 7px !important;
  }
}

/* modal styling for buyer/FarmerOrTrader purchase history screen */
.buyerOrFarmerTraderHistoryModal .ant-modal-body {
  padding-bottom: 5px !important;
}

/* farmer/trader purchase history report table style */
.historyTable .ant-table.ant-table-small {
  font-size: 13px !important;
}

.printModal {
  font-size: 13px !important;
}

.weighbridgeDiv .ant-statistic-title {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: $colorBlack !important ;
  margin-bottom: 0 !important;
  text-align: center !important;
}

.weighbridgeDiv .ant-statistic-content {
  font-size: 14px !important;
  color: $colorBlack !important ;
  text-align: center !important;
  align-items: center !important;
}
