$appBgColor: #f5f5f5;
$errorColor: #f5222d;
$inputPrefixBgColor: #fafafa;
$inputPrefixBorder: #d2d2d2;
$navBarBgColor: #ffff5d;
$navBarLogoColor: #ffff00;
$colorBlack: black;
$colorWhite: white;
$placeholderTextColor: #bfbfbf;
$deleteUserIconColor: #ce0e2d;
$asteriskColor: red;
$colorTransparent: 'transparent';
$tintColor: #1890ff;
$informationIconColor: #faad14;
$labelColor: #4d4d4d;
$grnTextColor: #6d6d6d;
$highLightColor: #d1ffd1;
$helpTextColor: #a2a2a2;
$colorGreen: #3aab02;
