@import './../../../scss/variables.scss';

.reportTableContainer {
  th {
    color: #000595d9 !important;
    font-weight: 600 !important;
  }
}

.labelStyle {
  font-weight: bold;
  color: $labelColor;
}

.tableColumnNameStyle {
  font-weight: normal;
  font-size: 12;
}
